
import React, { Component } from 'react';
import { DataGrid, } from '@mui/x-data-grid';
import { Apiurl } from "../services/rest";
import axios from "axios";
import Cookies from "js-cookie";
import '../assetss/css/botonFlotante.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRotate, faRoute, faSave, faSignOutAlt,faLocationDot, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap'
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material';
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import Header from '../template/Header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'bootstrap/dist/css/bootstrap.css'


  
class DataSiberia extends Component {

    
    state = {
        pedidos: [],
        selectedRows: [],
        isLoading: false,
        isLoadBoard: false,
        isLoadZone:false,
        emergenteFiltro:false,
        itmFiltro: []
      };
    
    
    
      componentDidMount() {
        console.log("llamado")
        let url = Apiurl + "getOrderSiberia/";
        axios.get(url).then((response) => {
          this.setState({
            pedidos: response.data.data,
          });
        });
        const handleBeforeUnload = () => {
          //Cookies.remove('token');
    
        };
    
        // Agregar el event listener para el evento beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Recuerda también eliminar el event listener en componentWillUnmount
        this.cleanupBeforeUnload = () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    
      }

      agregarElemento = (nuevoElemento) => {
        console.log(this.state.itmFiltro)
        this.setState((prevState) => ({
          itmFiltro: [...prevState.itmFiltro, {columna:nuevoElemento.columna, operador: nuevoElemento.operador, valor: nuevoElemento.valor, opl: nuevoElemento.opl }],
        }));
      };
    
      eliminarElemento = (indice) => {
        console.log(this.state.itmFiltro.length)
        this.setState((prevState) => ({
          itmFiltro: prevState.itmFiltro.filter((_, i) => i !== indice),
        }));
      };

      handleSelectionChange = (newRowSelectionModel) => {
        this.setState({
            selectedRows: newRowSelectionModel
        }); 
      }
      ventanaFiltro=()=>{
        this.setState({emergenteFiltro: !this.state.emergenteFiltro});
      }
      handleLogout() {
        Cookies.remove('token');
        window.location.href = '/';
      }
      handlePreroute(){
        const token = Cookies.get('token');

        if (token) {
          window.location.href = "/prerutas";
        }
      }

      btnstate = () => {
        console.log("funciono")
        const isLoading = this.state.isLoading;
        if (isLoading=== true){
          console.log("boton activo")
          this.setState({isLoading: false});
        }else{
          console.log("boton inactivo")
          this.setState({isLoading: true});          
        }
      }


      actDashboard = () => {
        let url = Apiurl + "/dispararBogota/";
        this.setState({isLoadBoard: true});
        axios.post(url).then((response) => {
          console.log(response.data);
          this.setState({isLoadBoard: false});
          window.location.reload();
        });
      }
      actZonas = () => {
        let url = "https://recolecciones.homedeliveryf.com/zonificacion_Bogota/api/";
        this.setState({isLoadZone: true});
        axios.get(url).then((response) =>{
          console.log(response.data);
          this.setState({isLoadZone: false});
          window.location.reload();
        });
      }

      printSelectedRows = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length === 0){
          swal({
            title:"Estado de carga de pedidos",
            text:"no hay pedidos seleccionados",
            icon:"warning",
            buttons:"Aceptar"
          }).then(() => {
            window.location.reload();
          });
        }
        else
        {
          this.setState({isLoading: true});
          const jsonObject = {
            tracking_id: selectedRows.map(row => row)
          };
          console.log(jsonObject)
          let url = Apiurl + "createOrdersSR_Siberia/";
          axios
            .post(url, jsonObject)
            .then((response) => {
              
              //console.log(response.data.url);
              var url = response.data.url
              //this.openUrl(url)
              //this.downloadFile(response.data.url,"planeacion_rutas.xlsx")
              this.setState({isLoading: false});
              swal({
                title:"Estado de carga de pedidos",
                text:"los pedidos se cargaron correctamente ",
                icon:"success",
                buttons:"Aceptar"
              }).then(() => {
                window.location.href = url;
                
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({isLoading: false});
              swal({
                title:"Estado de carga de pedidos",
                text:"Error de carga",
                icon:"error",
                buttons:"Aceptar"
              }).then(() => {
                window.location.reload();
              });
            });
          }
       }


      render(){
        const token = Cookies.get('token');
        //const token = localStorage.getItem("token");
        //console.log(token)
        if (!token) {
          window.location.href = '/'; // Redirige al componente Login
        }
    
        const { selectedRows } = this.state.selectedRows;

      
        const columns = [
            { field: 'tracking_id', headerName: 'Tracking ID', width: 150 },
            { field: 'status', headerName: 'Status', width: 150 },
            { field: 'nombreFormaEntrega', headerName: 'nombreFormaEntrega', width: 100 },
            { field: 'destino', headerName: 'Destino', width: 200 },
            { field: 'direccion', headerName: 'Dirección', width: 400 },
            { field: 'destinatario', headerName: 'Destinatario', width: 200 },
            { field: 'PromesaEntrega', headerName: 'PromesaEntrega', width: 200 },
          ];
      
          const rows = this.state.pedidos.map((pedido) => ({
            id: pedido.tracking_id,
            tracking_id: pedido.tracking_id,
            status: pedido.status,
            nombreFormaEntrega: pedido.title,
            destino: pedido.destino,
            direccion: pedido.direccion,
            destinatario: pedido.destinatario,
            PromesaEntrega:pedido.PromesaEntrega,
          }));

          

          return (
            
            <>
            <div style={{ height: '100vh', width: '100%' }}>
              <Header linkText="SIBERIA"></Header>
              <Helmet>
                <title>enrutador</title>
              </Helmet>
              <DataGrid
                style={{
                  background: '#f5f5f5', // Color de fondo principal
                  border: '1px solid #ccc', // Borde alrededor del DataGrid
                  borderRadius: '8px', // Bordes redondeados
                }}
                rows={rows}
                columns={columns}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 50,
                    },
                  },
                }}
                pageSize={5}
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={this.handleSelectionChange} />


              <button type="button" className="floating-button" onClick={this.printSelectedRows} disabled={this.state.isLoading}
                style={{
                  backgroundColor: this.state.isLoading ? "#ccc" : "#007bff",
                }}
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
              <button type="button" className="floating2-button" onClick={this.handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </button>
              <button type="button" className="floating3-button" onClick={this.handlePreroute}>
                <FontAwesomeIcon icon={faRoute} />
              </button>
              <button type="button" className="floating4-button" onClick={this.actDashboard} disabled={this.state.isLoadBoard}
                style={{
                  backgroundColor: this.state.isLoadBoard ? "#ccc" : "#ee73d9",
                }}>
                <FontAwesomeIcon icon={faRotate} />
              </button>
              <button type="button" className="floating5-button" onClick={this.actZonas} disabled={this.state.isLoadZone}
                style={{
                  backgroundColor: this.state.isLoadZone ? "#ccc" : "#55d7f8",
                }}>
                <FontAwesomeIcon icon={faLocationDot} />
              </button>
              <button type="button" className="floating6-button" onClick={this.ventanaFiltro}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <Modal isOpen={this.state.emergenteFiltro}>
            <ModalHeader>
              CRITERIO DE FILTRADO
            </ModalHeader>
            <ModalBody>
                {columns.map((field, index)=>(
                  <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{field.field}</Typography>
              
            </AccordionSummary>
            <AccordionDetails>
            {this.state.itmFiltro.map((objeto, index) => (
                  objeto.columna === field.field && (
                    <div key={index}>
                      <select style={{ marginRight: '10px' }} onChange={(e) => {
                      objeto.operador = e.target.value;
                      this.setState({ itmFiltro: this.state.itmFiltro });
                    }}>
                       <option  selected={objeto.operador === "Contiene"}>Contiene</option>
                       <option  selected={objeto.operador === "No Contiene"}>No Contiene</option>
                        <option  selected={objeto.operador === "Igual"}>Igual</option>
                        <option  selected={objeto.operador === "Comienza con"}>Comienza con</option>
                        <option  selected={objeto.operador === "Termina con"}>Termina con</option>
                        <option  selected={objeto.operador === "vacío"}>vacío</option>
                        <option  selected={objeto.operador === "No vacío"}>No vacío</option>
                        <option  selected={objeto.operador === "Es algo como"}>Es algo como</option>
                      </select>
                      
                      <input type="text" placeholder={objeto.valor} onChange={(e) => {
                        objeto.valor = e.target.value;
                        this.setState({ itmFiltro: this.state.itmFiltro });
                      }}/>
                      <select style={{ marginRight: '10px' }} onChange={(e) => {
                      objeto.opl = e.target.value;
                      this.setState({ itmFiltro: this.state.itmFiltro });
                    }}>
                       <option  selected={objeto.opl === "AND"}>AND</option>
                       <option  selected={objeto.opl === "OR"}>OR</option>
                      </select>
                    </div>
                  )
                ))}  
                 <Button onClick={() => this.agregarElemento({ columna: field.field,operador: 'Contiene', valor: '',opl: 'AND' })}>Agregar</Button> 
                 
            </AccordionDetails>
          </Accordion>
                ))}
            </ModalBody>
            <ModalFooter>
                
               <Button onClick={this.ventanaFiltro}>Cerrar</Button> 
               <Button >Guardar</Button> 
            </ModalFooter>
            </Modal>
        </>
          );

          
      }

}

export default DataSiberia;