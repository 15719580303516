import 'handsontable/dist/handsontable.full.css';
import { HotTable, HotColumn } from '@handsontable/react';
import {registerAllModules} from 'handsontable/registry'
import React, { Component } from 'react';
import { Apiurl } from "../services/rest";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons';


registerAllModules()

class DataSiberiaMKIII extends Component {

    state = {
        pedidos: [],
        selectedRows: [],
        isLoading: false,
        isLoadBoard: false,
        isLoadZone:false
      };

      componentDidMount() {
       // console.log("llamado")
        let url = Apiurl + "getOrderSiberia/";
        axios.get(url).then((response) => {
          this.setState({
            pedidos: response.data.data,
          });
        });
        const handleBeforeUnload = () => {
          //Cookies.remove('token');
    
        };
    
        // Agregar el event listener para el evento beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Recuerda también eliminar el event listener en componentWillUnmount
        this.cleanupBeforeUnload = () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    
      }

      
    render(){
        const datos = this.state.pedidos.map((pedido) => ({
            id: pedido.tracking_id,
            tracking_id: pedido.tracking_id,
            status: pedido.status,
            ct: pedido.ct,
            OficinaActual: pedido.OficinaActual,
            nombreFormaEntrega: pedido.title,
            destino: pedido.destino,
            direccion: pedido.direccion,
            destinatario: pedido.destinatario,
            crossDock:pedido.CrossDock,
            Zona:pedido.Zona,
            PromesaEntrega:pedido.PromesaEntrega,
          }));
        return(
         
          <div style={{ height: '100vh', width: '100%' }}>
          <HotTable
            data={datos}
            rowHeaders={true}
            colHeaders={true}
            columnSorting={true}
            height="auto"
            filters={true}
            dropdownMenu={true}
            licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          >
              <HotColumn data="tracking_id" title='tracking_id' readOnly={true} />
              <HotColumn data="status" title='status' readOnly={true} />
              <HotColumn data="ct" title='ct' readOnly={true} />
              <HotColumn data="OficinaActual" title='OficinaActual' readOnly={true} />
              <HotColumn data="nombreFormaEntrega" title='nombreFormaEntrega' readOnly={true} />
              <HotColumn data="destino" title='destino' readOnly={true} />
              <HotColumn data="direccion" title='direccion' readOnly={true} />
              <HotColumn data="destinatario" title='destinatario' readOnly={true} />
              <HotColumn data="crossDock" title='crossDock' readOnly={true} />
              <HotColumn data="Zona" title='Zona' readOnly={true} />
              <HotColumn data="PromesaEntrega" title='PromesaEntrega' readOnly={true} />
            </HotTable>
            <button type="button" className="floating-button" >
                  <FontAwesomeIcon icon={faPlus} /> 
                </button>
            </div>
        )
            
        }
}
    



export default DataSiberiaMKIII;