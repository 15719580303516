import React from "react";

class Header extends React.Component{
    render(){
        const { linkText } = this.props;
        return(
            <nav className="navbar navbar-dark bg-dark">
            <a className="navbar-brand" href="https://linioservices.azurewebsites.net/">{ linkText }</a>
             </nav>
        );

    }
}

export default Header;