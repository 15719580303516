import React, { Component } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Apiurl } from "../services/rest";
import axios from "axios";
import Cookies from "js-cookie";

class DropdownList extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      rutasConPaquetes: [],
    };
  }
  componentDidMount() {
    const rol = Cookies.get('rol');
    let url = Apiurl + "getRoutes/?model="+rol.toString();

    axios.get(url).then((response) => {
        this.setState({ 
            rutasConPaquetes: response.data 
        });
    });

  }

  CreateRoutes = (idruta) => {
    console.log(idruta)
    let url = Apiurl + "CreatePru/?idRuta="+idruta;

    axios.post(url, {})
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log('Respuesta exitosa:', response.data);
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error('Error en la solicitud POST:', error);
      });
  }

  render() {
    const token = Cookies.get('token');
        //const token = localStorage.getItem("token");
        console.log(token)
        if (!token) {
          window.location.href = '/'; // Redirige al componente Login
        }
    return (
      <div>
        {this.state.rutasConPaquetes.map((ruta, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{ruta.ruta}</Typography>
              
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {ruta.paquetes.map((paquete, paqueteIndex) => (
                  <li key={paqueteIndex}>
                    {paquete.ord} {paquete.numeroEnvio} 
                  </li>
                ))}
              </ul>
            </AccordionDetails>
            <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                onClick={() => this.CreateRoutes(ruta.ruta)}
              >
                Cargar preruta
              </Button>
          </Accordion>
        ))}
      </div>
    );
  }
}

export default DropdownList;
