import React from "react";
import Header from "../template/Header";
import axios from "axios";
import { Apiurl } from "../services/rest";
import { useTable, useFilters } from "react-table";
import '../assetss/css/botonFlotante.css';
import '../assetss/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import Cookies from "js-cookie";




// Define una función que filtra los datos según un valor de búsqueda
const defaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter, id },
}) => {
  const count = preFilteredRows.length;



  return (
    <>
      <input
        className="form-control" 
        type="text"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); 
        }}
        placeholder={`Buscar ${count} registros...`}
      />
    </>
  );
};

const filterList = ({
  column: { filterValue, preFilteredRows, setFilter, id },
}) => {


  const options = [
    ...new Set(preFilteredRows.map((row) => row.values[id]))
  ];
  

  return (
    <>
       <select
        className="form-control"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
          {options.map((option) => ( 
            <option key={option} value={option}>
              {option}
            </option>
          ))}   
      </select>
    </>
  );
};


const Table = ({ columns, data }) => {
  // Configura la tabla y las propiedades de filtrado de cada columna
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: { 
          Filter: defaultColumnFilter,
          filterList: filterList,
        },
      },
      useFilters // Agrega el hook useFilters para habilitar la funcionalidad de filtrado por columna
    );
    function handleLogout() {
      Cookies.remove('token');
      window.location.href = '/';
    }
  const handlePrintButtonClick = () => {
    const formattedRows = rows
      .map((row) =>
        Object.entries(row.original)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n")
      )
      .join("\n\n"); // Puedes personalizar el formato y separador según tus necesidades

    console.log(formattedRows);
    const enviosArray = formattedRows.split("\n\n"); // Dividimos el string en varios strings, separados por 2 saltos de línea

    const data = enviosArray.reduce(
      (result, envioString) => {
        // Dividimos cada string en varias líneas, y por cada línea extraemos el nombre y el valor separados por ": "
        const envioLines = envioString.split("\n");
        const envio = envioLines.reduce((envioResult, line) => {
          const [name, value] = line.split(": ");
          envioResult[name] = value.trim(); // Guardamos el valor sin espacios en blanco al principio y al final
          return envioResult;
        }, {});

        // Agregamos el tracking_id al arreglo resultante
        result.tracking_id.push(envio.tracking_id);

        return result; // Devolvemos el objeto resultante hasta el momento
      },
      { tracking_id: [] }
    );
    console.log(data);
    let url = Apiurl + "createOrdersSR/";
    axios
      .post(url, data)
      .then((response) => {
        console.log(response.data);
        swal({
          title:"Estado de carga de pedidos",
          text:"los pedidos se cargaron correctamente https://app2.simpliroute.com/#/login",
          icon:"success",
          buttons:"Aceptar"
        });
      })
      .catch((error) => {
        console.log(error);
        swal({
          title:"Estado de carga de pedidos",
          text:"Error de carga",
          icon:"error",
          buttons:"Aceptar"
        });
      });
  };

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button type="button" className="floating-button" onClick={handlePrintButtonClick}>
        <FontAwesomeIcon icon={faSave} /> 
      </button>
      <button type="button" className="floating2-button" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> 
      </button>
    </>
  );
};

class Dashboard extends React.Component {


  state = {
    pedidos: [],
  };

  componentDidMount() {
    let url = Apiurl + "getOrders/";
    axios.get(url).then((response) => {
      this.setState({
        pedidos: response.data.data,
      });
    });
    const handleBeforeUnload = () => {
      Cookies.remove('token');

    };

    // Agregar el event listener para el evento beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Recuerda también eliminar el event listener en componentWillUnmount
    this.cleanupBeforeUnload = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }

  componentDidUpdate(prevProps, prevState) {
    // Verifica si el estado de isAuthenticated cambió
    if (this.state.isAuthenticated !== prevState.isAuthenticated) {
      // Si el usuario ya no está autenticado, redirige al componente Login
      if (!this.state.isAuthenticated) {
        window.location.href = '/'; // Redirige al componente Login
      }
    }
  }

  render() {
    const token = Cookies.get('token');
    //const token = localStorage.getItem("token");
    console.log(token)
    if (!token) {
      window.location.href = '/'; // Redirige al componente Login
    }

    const columns = [
      {
        Header: "tracking_id",
        accessor: "tracking_id",
      },
      {
        Header: "status",
        accessor: "status",
        Filter: filterList,
      },
      {
        Header: "ct",
        accessor: "ct",
        Filter: filterList,
      },
      {
        Header: "BU_Fulfillment",
        accessor: "BU_Fulfillment",
        Filter: filterList,
      },
      {
        Header: "destino",
        accessor: "destino",
        Filter: filterList,
      },
      {
        Header: "direccion",
        accessor: "direccion",
      },
      {
        Header: "complemento_direccion",
        accessor: "complemento_direccion",
      },
    ];

    return (
      <React.Fragment>
        <Header />
        <Table columns={columns} data={this.state.pedidos} />
      </React.Fragment>
    );
  }
}

export default Dashboard;
