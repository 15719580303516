import React from "react";
import axios from "axios";
import '../assetss/css/login.css'
import logo from '../assetss/img/image-removebg-preview.png'
import { Apiurl } from "../services/rest";
import Cookies from "js-cookie";
import { Helmet } from 'react-helmet';


class Login extends React.Component{
  state = {
    usuario: "",
    password: "",
    error: null,
  };
  handleSubmit = async (e) => {
    e.preventDefault();

    const { usuario, password } = this.state;
    try {
      let url = Apiurl + "loginUser/";
      const response = await axios.post(url, {
        usuario,
        password,
      });

      const { status,token,rol } = response.data;
      console.log(rol)

      if (status !== 'usuario valido'){
        this.setState({ error: "Error al iniciar sesión. Verifica tus credenciales." });
      }else{
          const expirationTime = 1800;//3600; 
          const expirationDate = new Date().getTime() + expirationTime * 1000;
          Cookies.set('token', token, { expires: new Date(expirationDate) });
          Cookies.set('rol', rol, { expires: new Date(expirationDate) });
          //localStorage.setItem("token", token);
          this.props.handleAuthentication(true);
          if (rol === 'cali'){
            window.location.href = "/carga_pedidos";
          }else if(rol === 'siberia'){
            window.location.href = "/carga_pedidos_siberia";
          }
          
      }

    } catch (error) {
      this.setState({ error: "Error al iniciar sesión. Verifica tus credenciales." });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

    render(){
      const { usuario, password, error } = this.state;
        return(
            <React.Fragment>
            <div className="main">
            <Helmet>
                <title>login</title>
            </Helmet>
  <div className="container">
    <center>
      <div className="middle">
        <div id="login">
          <form onSubmit={this.handleSubmit}>
            <fieldset className="clearfix">
              <p>
                <span className="fa fa-user"></span>
                <input type="text" placeholder="Username" name="usuario" value={usuario} onChange={this.handleChange} required />
              </p>
              <p>
                <span className="fa fa-lock"></span>
                <input type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange} required />
              </p>
              {error && <p className="error-message">{error}</p>}
              <div>
                <span style={{ width: "48%", textAlign: "left", display: "inline-block" }}>
                  <a className="small-text" href="https://linioservices.azurewebsites.net/">
                    Forgot password?
                  </a>
                </span>
                <span style={{ width: "50%", textAlign: "right", display: "inline-block" }}>
                  <input type="submit" value="Sign In" />
                </span>
              </div>
            </fieldset>
            <div className="clearfix"></div>
          </form>
          <div className="clearfix"></div>
        </div>
        <img src={logo} alt="logo empresa" style={{ maxWidth: "300px", height: "auto" }} />
        <div className="clearfix"></div>
      </div>
    </center>
  </div>
</div>

            </React.Fragment>
        );
    }
}

export default Login