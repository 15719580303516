
import React, { Component } from 'react';
import { DataGridPro, } from '@mui/x-data-grid-pro';
import { Apiurl } from "../services/rest";
import axios from "axios";
import Cookies from "js-cookie";
import '../assetss/css/botonFlotante.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faRoute, faSave, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import Header from '../template/Header';


  
class MyDataGrid extends Component {

    
    state = {
        pedidos: [],
        selectedRows: [],
        isLoading: false,
        isLoadBoard: false
      };
    
      componentDidMount() {
        console.log("llamado")
        let url = Apiurl + "getOrders/";
        axios.get(url).then((response) => {
          this.setState({
            pedidos: response.data.data,
          });
        });
        
        const handleBeforeUnload = () => {
          //Cookies.remove('token');
    
        };
       
        
        // Agregar el event listener para el evento beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Recuerda también eliminar el event listener en componentWillUnmount
        this.cleanupBeforeUnload = () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    
      }

      handleSelectionChange = (newRowSelectionModel) => {
        this.setState({
            selectedRows: newRowSelectionModel
        }); 
      }

      handleLogout() {
        Cookies.remove('token');
        window.location.href = '/';
      }
      handlePreroute(){
        const token = Cookies.get('token');

        if (token) {
          window.location.href = "/prerutas";
        }
      }

      btnstate = () => {
        console.log("funciono")
        const isLoading = this.state.isLoading;
        if (isLoading=== true){
          console.log("boton activo")
          this.setState({isLoading: false});
        }else{
          console.log("boton inactivo")
          this.setState({isLoading: true});          
        }
      }
      actDashboard = () => {
        let url = Apiurl + "/dispararCali/";
        this.setState({isLoadBoard: true});
        axios.post(url).then((response) => {
          console.log(response.data);
          this.setState({isLoadBoard: false});
          window.location.reload();
        });
      }
      printSelectedRows = () => {
        const { selectedRows } = this.state;
        if (selectedRows.length === 0){
          swal({
            title:"Estado de carga de pedidos",
            text:"no hay pedidos seleccionados",
            icon:"warning",
            buttons:"Aceptar"
          }).then(() => {
            window.location.reload();
          });
        }
        else
        {
          this.setState({isLoading: true});
          const jsonObject = {
            tracking_id: selectedRows.map(row => row)
          };
          console.log(jsonObject)
          let url = Apiurl + "createOrdersSR/";
          axios
            .post(url, jsonObject)
            .then((response) => {
              console.log(response.data);
              this.setState({isLoading: false});
              this.setState({selectedRows:[]});
              swal({
                title:"Estado de carga de pedidos",
                text:"los pedidos se cargaron correctamente https://app2.simpliroute.com/#/login",
                icon:"success",
                buttons:"Aceptar"
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({isLoading: false});
              swal({
                title:"Estado de carga de pedidos",
                text:"Error de carga",
                icon:"error",
                buttons:"Aceptar"
              }).then(() => {
                window.location.reload();
              });
            });
          }
       }


      render(){
        const token = Cookies.get('token');
        //const token = localStorage.getItem("token");
        console.log(token)
        
        if (!token) {
          window.location.href = '/'; // Redirige al componente Login
        }
    
        const { selectedRows } = this.state.selectedRows;

        
        const columns = [
            { field: 'tracking_id', headerName: 'Tracking ID', width: 150 },
            { field: 'status', headerName: 'Status', width: 150 },
            { field: 'ct', headerName: 'CT', width: 100 },
            { field: 'nombreFormaEntrega', headerName: 'nombreFormaEntrega', width: 100 },
            { field: 'BU_Fulfillment', headerName: 'BU Fulfillment', width: 200 },
            { field: 'destino', headerName: 'Destino', width: 200 },
            { field: 'direccion', headerName: 'Dirección', width: 400 },
            { field: 'destinatario', headerName: 'Destinatario', width: 200 },
          ];
      
          const rows = this.state.pedidos.map((pedido) => ({
            id: pedido.tracking_id,
            tracking_id: pedido.tracking_id,
            status: pedido.status,
            ct: pedido.ct,
            nombreFormaEntrega: pedido.title,
            BU_Fulfillment: pedido.BU_Fulfillment,
            destino: pedido.destino,
            direccion: pedido.direccion,
            destinatario: pedido.destinatario,
          }));
      
          return (
            <div style={{ height: '100vh', width: '100%' }}>
            <Header linkText="Cali"></Header>
            <Helmet>
                <title>enrutador</title>
            </Helmet>
              <DataGridPro
                 style={{
                  background: '#f5f5f5', // Color de fondo principal
                  border: '1px solid #ccc', // Borde alrededor del DataGrid
                  borderRadius: '8px', // Bordes redondeados
                }}
                rows={rows}
                columns={columns}
                pagination
                initialState={{

                    pagination: {
                    paginationModel: {
                        pageSize: 50,
                    },
                    },
                }}
                pageSize={5}
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={this.handleSelectionChange} 
                />


                <button type="button" className="floating-button" onClick={this.printSelectedRows}  disabled={this.state.isLoading} 
                          style={{
                            backgroundColor: this.state.isLoading ? "#ccc" : "#007bff",
                          }}
                >
                    <FontAwesomeIcon icon={faSave} /> 
                </button>
                <button type="button" className="floating2-button" onClick={this.handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> 
                </button>
                <button type="button" className="floating3-button" onClick={this.handlePreroute}>
                  <FontAwesomeIcon icon={faRoute} /> 
                </button>
                <button type="button" className="floating4-button" onClick={this.actDashboard} disabled={this.state.isLoadBoard} 
                          style={{
                            backgroundColor: this.state.isLoadBoard ? "#ccc" : "#ee73d9",
                          }}>
                  <FontAwesomeIcon icon={faRotate} /> 
                </button>
            </div>
          );
      }

}

export default MyDataGrid;