
import './assetss/css/App.css';
import 'bootstrap/dist/css/bootstrap.css'
import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import MyDataGrid from './components/dashboar2'
import DataSiberia from './components/siberiaDashboard';
import DropdownList from './components/PRRconfirm'
import DataSiberiaMKIII from './components/DashboardMRK3'



function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleAuthentication = (value) => {
    setIsAuthenticated(value);
  };
  useEffect(() => {
    console.log('isAuthenticated after handleAuthentication:', isAuthenticated);

  }, [isAuthenticated]);
  return (
    <Router>
      <Routes>
        <Route
            path="/"
            element={<Login isAuthenticated={isAuthenticated} handleAuthentication={handleAuthentication} />}
          />
        <Route
          path="/Dashboard"
          element={<Dashboard isAuthenticated={isAuthenticated} />}
        />
         <Route
            path="/carga_pedidos"
            element={
              
                <MyDataGrid isAuthenticated={isAuthenticated} />
             
            }
          />
         <Route
            path="/carga_pedidos_siberia"
            element={
              
                <DataSiberia isAuthenticated={isAuthenticated} />
             
            }
          />
          <Route
            path="/prerutas"
            element={
              
                <DropdownList isAuthenticated={isAuthenticated} />
             
            }
          />
          <Route
            path="/PanelRuteo"
            element={
              
                <DataSiberiaMKIII/>
             
            }
          />
      </Routes>
    </Router>

  );
}

export default App;
